import React from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom"
//import Images from '../../Images'

import './styles.less'

function NotFoundPage() {
  let history = useHistory();

  function handleNavigate(path) {
    history.push(path);
  }

  return (
    <div className="not-found"
         /*style={{ background: `center / cover url(${setBgImage()})`, backgroundSize: 'cover' }} */
         onClick={() => handleNavigate('/bl')}>
      {/*<img src={Images.logo} alt=""/>*/}
      <div className="not-found-content">
        <div>404</div>
        <p>Unfortunately, this page doesn’t exist.</p>
        <p>Try <Link to={'/bl'}>
          start from the main page
        </Link>
        </p>
      </div>
    </div>
  )
}

export default NotFoundPage
