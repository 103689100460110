import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {Router, Switch, Route} from 'react-router-dom'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {Layout} from 'antd'
import {withResizeDetector} from 'react-resize-detector'
import {history} from '../../Utils/utils'

// COMPONENTS
import CustomLayout from '../Layout'
import StartupPage from '../StartupPage'
import NotFoundPage from '../NotFoundPage'
import BLPage from '../BLPage'

import openNotification from '../../Components/Notification/index'

// REDUCERS
import NavActions from '../../Redux/NavRedux'
import ErrorsActions from '../../Redux/ErrorsRedux'
import StartupCreators from '../../Redux/StartupRedux'

// HOC
import useWindowSize from '../../hooks/use-window-size'

function Routes(props) {
  const {
    width,
    navSetProp,
    error,
    startup,
    dispatch,
  } = props

  const [, innerHeight] = useWindowSize()

  // STARTUP
  useEffect(() => {
    dispatch(StartupCreators.startup())
  }, [])

  useEffect(() => {
    if (innerHeight) document.documentElement.style.setProperty('--app-height', `${innerHeight}px`)
  }, [innerHeight])

  // ERRORS
  useEffect(() => {
    if (error) {
      if (error.message) {
        for (const i in error.message) {
          //message.error(`${i}: ${error.message[i]}`, 5);
          openNotification({
            type: 'error',
            message: `${i}: ${error.message[i]}`,
            style: {minWidth: '716px'},
            key: i,
          })
        }
      } else if (typeof error === 'string') {
        //message.error(error, 5);
        openNotification({
          type: 'error',
          message: error,
          style: {minWidth: '716px'},
          key: 'error notification',
        })
      } else {
        for (const i in error) {
          //message.error(`${i}: ${error[i]}`, 5);
          openNotification({
            type: 'error',
            message: `${i}: ${error[i]}`,
            style: {minWidth: '716px'},
            key: i,
          })
        }
      }
      props.errorReset()
    }
  }, [!!error])

  // RESIZE
  useEffect(() => {
    const correctWidth = width || window.innerWidth
    if (correctWidth > 768) {
      navSetProp('isDesktop', true)
      navSetProp('isMobile', false)
    } else {
      navSetProp('isDesktop', false)
      navSetProp('isMobile', true)
    }
  }, [width])

  if (startup?.success === false) {
    return (
      <Layout>
        <Route path="*" component={StartupPage}/>
      </Layout>
    )
  }

  return (
    <Router history={history}>
      <Layout className="main-layout">
        <CustomLayout>
          <Switch>
            <Route exact path="/" component={StartupPage}/>
            <Route path="/bl/:id" component={BLPage}/>
            <Route path="/*" component={NotFoundPage}/>
          </Switch>
        </CustomLayout>
      </Layout>
    </Router>
  )
}

const mapStateToProps = (state) => ({
  startup: state.startup,
  isDesktop: state.nav.isDesktop,
  isMobile: state.nav.isMobile,
  error: state.errors.data,
})

const mapDispatchToProps = (dispatch) => ({
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),
  errorReset: () => dispatch(ErrorsActions.errorReset()),
  dispatch,
})

Routes.propTypes = {
  startup: PropTypes.shape({
    success: PropTypes.bool,
  }),
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  error: PropTypes.any,
  dispatch: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(compose(withResizeDetector)(Routes))
