import React from 'react';
import cls from 'classname';

export const SearchAlibaba = ({ className }) => (
  <svg width="36"
       height="18"
       fill="none"
       className={cls('icon', className)}
       viewBox="0 0 36 18"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5871 15.33C20.1426 15.396 20.2896 14.6415 21.1341 13.479C23.1141 10.827 26.8641 7.12497 26.9931 4.50597C27.2196 1.08148 23.7786 -0.0330241 20.2416 -2.4076e-05C17.7741 0.0329759 15.2256 0.737976 13.4901 1.35898C7.50061 3.47248 3.73411 6.86397 1.36411 10.614C-1.15139 14.331 -0.323387 17.8845 4.98361 18C9.05761 17.8365 11.7036 16.707 14.4636 15.297C14.4786 15.297 6.83461 17.4765 4.02661 15.8715L4.01461 15.8685C3.72511 15.7035 3.40861 15.4725 3.32911 14.844C3.31111 13.5165 5.51911 12.141 6.75361 11.6985V9.39897C7.68844 9.76285 8.68297 9.94903 9.68611 9.94797C11.5917 9.95046 13.4367 9.27825 14.8941 8.05047C14.9496 8.24547 14.9781 8.46747 14.9601 8.72097H15.5166C15.5886 8.12997 15.2586 7.66197 15.2586 7.66197C14.7591 6.86847 13.8861 6.88197 13.8861 6.88197C13.8861 6.88197 14.3586 7.08747 14.6796 7.58097C13.7243 8.38254 12.5826 8.93075 11.3597 9.17507C10.1368 9.41938 8.87209 9.35195 7.68211 8.97897L9.49711 7.17897L8.99311 5.86797C12.6456 4.58997 15.7131 3.60748 20.7111 2.74048L19.5921 1.80448L20.1756 1.44448C23.1906 2.29648 25.1631 2.92198 25.0551 4.52097C24.9914 4.83757 24.8897 5.1453 24.7521 5.43747C23.8761 7.17447 21.2631 10.0725 20.2086 11.2845C19.5111 12.087 18.8286 12.8745 18.3411 13.6275C17.8386 14.382 17.5311 15.084 17.5146 15.7575C17.5791 21.0135 33.0156 13.2975 36.0006 11.253C31.5861 13.152 26.8311 14.9865 21.5871 15.33Z"
      fill="#237CFB"/>
  </svg>
);

export default SearchAlibaba;
