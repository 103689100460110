import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from './Utils/utils';
import { reducer as startup } from './Redux/StartupRedux';
import { reducer as nav } from './Redux/NavRedux';
import { reducer as errors } from './Redux/ErrorsRedux';

import { reducer as bl } from './Containers/BLPage/reducer';


export const reducers = combineReducers({
  nav,
  errors,
  startup,
  bl,
  router: connectRouter(history),
});

export default reducers;
