import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './store';

store()
  .then((s) => {
    ReactDOM.render(<App store={s} />, document.getElementById('root'));
  })
  .catch((err) => {
    console.error(err.message);
  });
