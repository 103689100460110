import React from 'react';
//import cls from 'classname';
import { notification } from 'antd';
//import Icon from '../../Icon';

import NotificationClose from '../../Icon/img/NotificationClose';
//import NotificationSuccess from '../../Icon/img/NotificationSuccess';
//import NotificationWarning from '../../Icon/img/NotificationWarning';
//import NotificationError from '../../Icon/img/NotificationError';
//import NotificationDelete from '../../Icon/img/NotificationDelete';

import './styles.less';

/*const notificationsTypes = {
  success: <NotificationSuccess />,
  warning: <NotificationWarning />,
  info: <NotificationSuccess />,
  error: <NotificationError />,
  delete: <NotificationDelete />,
};*/

const openNotification = ({ type, message, style, getContainer, ...props }) => {
  notification[type]({
    message,
    duration: 3,
    top: 24,
    //icon: notificationsTypes[type] || notificationsTypes.success,
    closeIcon: <NotificationClose />,
    className: 'custom-notification',
    getContainer: () => getContainer || document.getElementById('global-wrap'),
    onClose: () => notification.destroy(),
    style: { ...style },
    ...props
  });
};

export default openNotification;
