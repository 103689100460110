import { takeLatest, put, call, delay } from 'redux-saga/effects';

import * as services from './services';
import Creators, { BLTypes as constants } from './reducer';
import ErrorsActions from '../../Redux/ErrorsRedux';

let data = {
  "id": "5dd8d26a-ba06-4f75-82b4-8653d3df49a2",
  "bol_type": "peninsula_trucklines",
  "email_recipients": ['customer@example.com'],
  "pdf_fields": {
    "consignee_name": "John Doe",
    "consignee_city": "Saint Paul",
    "consignee_state": "MN",
  },
  "date_updated": "2022-01-01",
  "date_created": "2022-01-01",
}

const actions = {
  getBLActions: {
    request: Creators.getBLRequest,
    success: Creators.getBLSuccess,
    errors: Creators.getBLFailure,
  },
  changeBLActions: {
    request: Creators.changeBLRequest,
    success: Creators.changeBLSuccess,
    errors: Creators.changeBLFailure,
  },
  confirmBLActions: {
    request: Creators.confirmBLRequest,
    success: Creators.confirmBLSuccess,
    errors: Creators.confirmBLFailure,
  },
};

const eventsOptions = {
  [constants.GET_BL_REQUEST]: {
    api: services.getBL,
    actions: actions.getBLActions,
  },
  [constants.CHANGE_BL_REQUEST]: {
    api: services.changeBL,
    actions: actions.changeBLActions,
  },
  [constants.CONFIRM_BL_REQUEST]: {
    api: services.approveBL,
    actions: actions.confirmBLActions,
  },
};

function* apiGenerator(action) {
  const provider = eventsOptions[action.type];

  if(action.payload.id === 'test' && action.type === constants.GET_BL_REQUEST){
    yield delay(3000)
    yield put(provider.actions.success(data));
  }else{
    try {
      const params = action.payload;
      const response = yield call(provider.api, params);
      if (response.ok && response.data) {
        yield put(provider.actions.success(response?.data));
      } else {
        yield put(ErrorsActions.errorSave('some error'));
        yield put(provider.actions.errors({ errors: false }));
        //yield put(ErrorsActions.errorSave(response?.data?.errors[0]));
      }
    } catch (errors) {
      yield put(ErrorsActions.errorSave(errors));
      yield put(provider.actions.errors({ errors }));
    }
  }
}

export default function* apiSaga() {
  yield takeLatest(constants.GET_BL_REQUEST, apiGenerator);
  yield takeLatest(constants.CHANGE_BL_REQUEST, apiGenerator);
  yield takeLatest(constants.CONFIRM_BL_REQUEST, apiGenerator);
}
