import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import StartupActions from '../Redux/StartupRedux';

import utils from '../Utils/utils';

const innerPages = [
  'bl',
];

// process STARTUP actions
export function* startup() {
  const { pathname, search } = document.location;
  if (pathname !== '/' && innerPages.some((el) => utils.includeCurrentLocation(pathname, el))) {
    yield put(push(search ? pathname + search : pathname));
  }

  yield put(StartupActions.startupSuccess());
}
