import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  getBLRequest: ['payload'], // payload: { id }
  getBLSuccess: ['payload'], // payload: { data }
  getBLFailure: ['payload'], // payload: { errors }

  changeBLRequest: ['payload'], // payload: { id, data },
  changeBLSuccess: ['payload'], // payload: { data },
  changeBLFailure: ['payload'], // payload: { errors },

  confirmBLRequest: ['payload'], // payload: { id },
  confirmBLSuccess: ['payload'], // payload: { data },
  confirmBLFailure: ['payload'], // payload: { errors },

  changeResultReset: null,
})

export const BLTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: false,
  result: null,
  errors: false,

  changeFetching: false,
  changeResult: null,

  confirmFetching: false,
  confirmResult: null,
})

/* ------------- Functions for reducer cases ------------- */

const getBLRequest = (state) => state.merge({fetching: true, result: null, errors: false,})
const getBLSuccess = (state, {payload: data}) => state.merge({fetching: false, result: data})
const getBLFailure = (state, {payload: {errors}}) => state.merge({fetching: false, errors})

export const changeBLRequest = (state) => state.merge({changeFetching: true})
export const changeBLSuccess = (state, {payload: data}) => state.merge({changeFetching: false, changeResult: true, result: data})
export const changeBLFailure = (state, {payload: {errors}}) => state.merge({changeFetching: false, errors})

export const confirmBLRequest = (state) => state.merge({confirmFetching: true})
export const confirmBLSuccess = (state, {}) => state.merge({confirmFetching: false, confirmResult: true})
export const confirmBLFailure = (state, {payload: {errors}}) => state.merge({confirmFetching: false, errors})

export const changeResultReset = (state) => {
  return state.merge({
    fetching: false,

    changeFetching: false,
    changeResult: null,

    confirmFetching: false,
    confirmResult: null,
  });
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_BL_REQUEST]: getBLRequest,
  [Types.GET_BL_SUCCESS]: getBLSuccess,
  [Types.GET_BL_FAILURE]: getBLFailure,

  [Types.CHANGE_BL_REQUEST]: changeBLRequest,
  [Types.CHANGE_BL_SUCCESS]: changeBLSuccess,
  [Types.CHANGE_BL_FAILURE]: changeBLFailure,

  [Types.CONFIRM_BL_REQUEST]: confirmBLRequest,
  [Types.CONFIRM_BL_SUCCESS]: confirmBLSuccess,
  [Types.CONFIRM_BL_FAILURE]: confirmBLFailure,

  [Types.CHANGE_RESULT_RESET]: changeResultReset,
})
