import { takeLatest, all, call } from 'redux-saga/effects';
//import AuthAPI from './Services/AuthApi';

// sagas
import BLSagas from './Containers/BLPage/saga';

/* ------------- Types ------------- */
import { StartupTypes } from './Redux/StartupRedux';

/* ------------- Sagas ------------- */

import { startup } from './Sagas/StartupSagas';

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
//const authApi = AuthAPI;

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // some sagas only receive an action
    takeLatest(StartupTypes.STARTUP, startup),
    call(BLSagas),
  ]);
}
