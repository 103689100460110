import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {Spin} from 'antd'
import Creators from './reducer'

import StepBillingForm from '../../Components/Forms/StepBillingForm'
import openNotification from '../../Components/Notification'

import './styles.less'

export const BLPage = (
  {
    fetching,
    error,
    bl,
    changeFetching,
    changeResult,
    confirmFetching,
    confirmResult,
    isMobile,
    match,
    ...props
  }) => {
  const {id} = match?.params

  useEffect(() => {
    document.title = 'Bill of lading'
  }, [])

  useEffect(() => {
    if (id) props.getBL({id})
  }, [id])

  useEffect(() => {
    if(changeResult){
      openNotification({
        type: 'success',
        message: 'Bill of lading has been successfully updated',
        style: { minWidth: '716px' },
      });
      props.changeResultReset();
    }

    if(confirmResult){
      openNotification({
        type: 'success',
        message: 'Bill of lading has been confirm',
        style: { minWidth: '716px' },
      });
      props.changeResultReset();
    }

  },[changeResult, confirmResult])

  const handleChangeBL = (data) => {
    props.changeBL({id, data})
  }

  const handleConfirmBL = () => {
    props.confirmBL({id})
  }

  return (
    <Spin size="large" spinning={fetching || changeFetching || confirmFetching}>
      <div className={'dashboard-page'}>
        <h1>Bill of lading</h1>

        {
          bl ? <StepBillingForm isMobile={isMobile}
                                preset={bl?.pdf_fields}
                                onSubmit={handleChangeBL}
                                onConfirm={handleConfirmBL}
            />
            : null
        }
      </div>
    </Spin>
  )
}

BLPage.defaultProps = {
  /*bl: {},*/
}

BLPage.propTypes = {
  fetching: PropTypes.bool,
  isMobile: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  fetching: state.bl.fetching,
  error: state.bl.errors,
  bl: state.bl.result,

  changeFetching: state.bl.changeFetching,
  changeResult: state.bl.changeResult,

  confirmFetching: state.bl.confirmFetching,
  confirmResult: state.bl.confirmResult,
  isMobile: state.nav.isMobile,
})

const mapDispatchToProps = (dispatch) => ({
  getBL: (data) => dispatch(Creators.getBLRequest(data)),
  changeBL: (data) => dispatch(Creators.changeBLRequest(data)),
  confirmBL: (data) => dispatch(Creators.confirmBLRequest(data)),
  changeResultReset: () => dispatch(Creators.changeResultReset()),

})

export default connect(mapStateToProps, mapDispatchToProps)(BLPage)
