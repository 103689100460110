import React, { useState, useEffect } from 'react';

import { Form, Button, Input, Row, Col } from 'antd';

import Icon from '../../Icon';
import Checkbox from '../Checkbox';

import './Form.less';

function StepBillingForm(props) {
  const { preset, isMobile } = props;

  const [state, setState] = useState({ });
  const [isChanged, setIsChanged] = useState(false);

  //const [errorText, setErrorText] = useState(null)

  const [form] = Form.useForm();

  const isPreset = !!preset;
  const formFields = Object.keys(preset) || []

  useEffect(() => {
    form.getFieldsValue();
  }, []);

  useEffect(() => {
    if (isPreset) setState({ ...preset });

  }, [isPreset]);

  const onFinish = async values => {
    props.onSubmit({ ...values });
  };

  const handleConfirm = () => {
    //const values = form.getFieldsValue();
    props.onConfirm();
  };

  function handleChange(changedValues, allValues) {
    setState({ ...state, ...changedValues });
    checkChanged()
  }

  const onFinishFailed = ({ errorFields }) => {
    //if (errorFields) setErrorText(errorFields[0]['errors'])
  };

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);

    //setErrorText(null)
  }

  function checkChanged(){
    let result = formFields.map(el => form.getFieldValue(el) !== preset[el]).some(el => el === true)
    //console.log('checkChanged', result)
    if(isChanged !== result) setIsChanged(result)
  }

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={handleChange}
      validateTrigger='onSubmit'
      //onFinishFailed={({ values, errorFields, outOfDate }) => console.log('onFinishFailed_____ cardElement', elements.getElement(CardNumberElement))}
      className='account-form payment-form on-boarding'
      autoComplete='off'
      requiredMark={false}
    >
      <Row gutter={{ xs: 12, md: 16 }} className='info-block'>
        <Col span={24}>
          <span className='block-title'>{'Information'}</span>
        </Col>
      </Row>

      <Row gutter={{ xs: 12, md: 16 }}>
        {
          formFields.map(el => (
           <Col xs={24} md={12} key={el}>
             <Form.Item
               label={el}
               name={el}
               initialValue={preset[el]}
               className={state?.[el] ? 'active' : ''}
               validateTrigger={'onBlur'}
             >
               <Input onFocus={e => handleClearError(e, el)}/>
             </Form.Item>
           </Col>
         ))
        }
      </Row>

      {
        isMobile
          ? (
            <>
              <div className={'double-btn-wrp'} style={{ marginTop: 'auto' }}>
                <Button type={'primary'}
                        className={'btn-primary border-only'}
                        htmlType='submit'
                        disabled={
                          // !form.isFieldsTouched(false) ||
                          form.getFieldsError().filter(({ errors }) => errors.length).length ||
                          !isChanged
                        }
                >
                  {'Change'}
                </Button>

                <Button type={'primary'}
                        className={'btn-primary'}
                        disabled={isChanged}
                        onClick={handleConfirm}
                  //onClick={handleNext}
                >
                  {'Confirm'}
                </Button>

              </div>
            </>)
          : (
            <div className='dialog-footer' style={{ justifyContent: 'space-between' }}>
              <Button className={'btn-primary border-only'}
                      htmlType='submit'
                      disabled={
                        // !form.isFieldsTouched(false) ||
                        form.getFieldsError().filter(({ errors }) => errors.length).length ||
                        !isChanged
                      }
              >
                {'Change'}
              </Button>

              <Button
                type="primary"
                className={'btn-primary'}
                style={{ minWidth: 217 }}
                onClick={handleConfirm}
                disabled={isChanged}
              >
                {'Confirm'}
              </Button>
            </div>
          )
      }


    </Form>
  );
}

export default StepBillingForm;
