import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import cls from 'classname';
import {Layout, Row, Col} from 'antd';

// actions
import actions from './actions';

import Logo from '../../Components/Logo';

// styles
import './styles.less';

export const LayoutComponent = ({
  nav,
  children,
  openSidebar,
  ...props
}) => {
  const { theme, isShowSideBar, isShowMenu } = nav;
  const { pathname } = useLocation();
  return (
    <Layout
      id="global-wrap"
      className={cls('global-wrap', {
        'theme-light': theme === 'light',
        'theme-dark': theme === 'dark',
        'closed-sidebar': isShowMenu,
        'opened-sidebar': !isShowMenu,
        'small-sidebar': isShowSideBar,
        'big-sidebar': !isShowSideBar,
        //'open-user-menu': isMobile,
      })}
    >
      {/*<header className="header">
        <div className="header_logo">
          <Logo theme={theme} />
        </div>
      </header>*/}
      <div className="main-container">
        <main className={`main-content ${pathname.slice(1).replace('/', '-')}`}>
          <Row>
            <Col flex="auto">{children}</Col>
          </Row>
        </main>
      </div>
    </Layout>
  );
};

LayoutComponent.defaultProps = {};

LayoutComponent.propTypes = {
  nav: PropTypes.shape({
    isMobile: PropTypes.bool,
    isShowMenu: PropTypes.bool,
    isShowSideBar: PropTypes.bool,
    isShowCartModal: PropTypes.bool,
    theme: PropTypes.string,
  }).isRequired,
  openSidebar: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const mapStateToProps = (state) => ({
  nav: state.nav,
});

const mapDispatchToProps = (dispatch) => ({
  openSidebar(value) {
    dispatch(actions.changeNavSetProp('isShowMenu', value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
