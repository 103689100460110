import React from 'react'
import PropTypes from 'prop-types'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {ConnectedRouter} from 'connected-react-router'
import {ConfigProvider} from 'antd'
import en from 'antd/lib/locale-provider/en_GB'

import {history} from './Utils/utils'
import InitComponent from './Containers/InitComponent'

import 'moment/locale/en-gb' // important!

import './index.less'
import './App.less'
import './Components/Forms/Form.less';

function App({store}) {
  return (
      <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
          <ConfigProvider locale={en}>
            <ConnectedRouter history={history}>
              <InitComponent/>
            </ConnectedRouter>
          </ConfigProvider>
        </PersistGate>
      </Provider>
  )
}

App.propTypes = {
  store: PropTypes.shape({
    store: PropTypes.object,
    persistor: PropTypes.object,
  }).isRequired,
}

export default App
